<template>
  <b-modal
    id="modal-publish-event"
    body-class="p-0"
    hide-header
    hide-footer
    centered
    size="md"
    no-close-on-backdrop
  >
    <div class="modal-publish-event">
      <div class="body">
        <h5 class="text-event-succuss">{{ textModal }}</h5>
        <p>คุณต้องการ Publish Event {{ this.$route.params.id }} หรือไม่ ?</p>
      </div>
      <div class="footer-modal">
        <b-button class="btn-filter" @click="closeModal"> Cancel </b-button>
        <div class="mx-1"></div>
        <b-button class="btn-filter" @click="updatePreviewOrPublish(1)">
          Publish
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    textModal: {
      type: String,
      default: null,
    },
  },
  methods: {
    updatePreviewOrPublish(val) {
      this.$emit("updatePreviewOrPublish", val);
      this.closeModal();
    },
    closeModal() {
      this.$bvModal.hide("modal-publish-event");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-publish-event {
  padding: 20px;

  .body {
    .text-event-succuss {
      text-align: center;
      margin-bottom: 10px;
    }

    p {
      margin: 0;
      text-align: center;
    }
  }

  .footer-modal {
    text-align: center;
    margin-top: 10px;
    display: flex;
    justify-content: center;

    .btn-filter {
      width: 100%;
      //   max-width: 100px;
    }
  }
}
</style>
